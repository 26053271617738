@use "@shopliftr/common-ng"as common;

@mixin theme {

    div {
        &.deals-api-container {
            max-width: 1160px;
            margin-left: auto;
            margin-right: auto;
        }

        &.static-content-container {
            width: 100%;
            text-align: left;
            overflow-x: auto;
        }

        &.header-section {
            display: inline-flex;

            div.header-container {
                width: 50%;

                &.version-section {
                    padding-left: 1.5em;
                }

                &.url-section {
                    padding-right: 1.5em;
                    text-align: right;
                }
            }

            h3 {
                font-weight: bolder;
                font-size: medium;
            }

            span {
                padding-left: 0.5em;
                font-weight: medium;
                color: common.$color-brand-dark;
            }
        }

        &.scheme-container {
            display: none;
        }

        &.information-container.wrapper {
            display: none;
        }

        &.opblock-tag-section {
            h3.opblock-tag {
                a {
                    width: 25%;
                }

                p {
                    text-align: right;
                    padding-right: 8em;
                    padding-top: 0.8em;
                    opacity: 0.5;
                }

                svg.arrow {
                    width: 12px;
                    height: 12px;
                }

                border-bottom-color: common.$color-border-light;
            }

            button.try-out__btn {
                border-color: common.$color-primary-medium;
                background-color: common.$color-primary-light-alt;
                color: common.$color-primary-contrast-lighter;
            }

            button.try-out__btn.cancel {
                color: common.$color-brand-base;
                border-color: common.$color-brand-dark;
            }

            code.language-json span {
                color: #fff !important;
            }
        }
    }



    iframe {
        height: calc(100% - 60px);
        width: 100%;
    }

    .mat-divider {
        margin-bottom: 0.5em !important;
        margin-top: 1em !important;
        border-color: common.$color-border-light;
    }
}