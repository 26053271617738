@use "@shopliftr/common-ng" as common;

.table {
    overflow-x: auto;
    width: 100%;

    table {
        width: 100%;

        tbody {
            tr {
                &:last-child {
                    td,
                    th {
                        border-bottom: none !important;
                    }
                }
            }
        }
    
        tr {
            th {
                color: common.$color-accent-dark;
            }
        
            td,
            th {
                border-bottom: common.$border;
                font-size: 12px !important;
                height: 56px;
                padding: 5px;
                text-align: left;
    
                & > mat-icon {
                    font-size: 20px;
                }
            }
        }
    }
}
