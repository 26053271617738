@use "@shopliftr/common-ng" as common;

* {
    //vertical-align: middle;

    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}

html, body {
	height: 100%;
}

body {
    font-family: common.get-font-family();
    font-size: 14px;
    line-height: 1.5;
    overflow-x: hidden;
}

a {
    text-decoration: none;

    &:hover {
        cursor: pointer;
    }
}

iframe#launcher {
    display: block !important;
}

p {
    &.lead {
        font-size: 1rem;
        font-weight: 300;
    }
}

.background {
    background-color: common.$color-primary-light;
}

.background-white {
    background-color: white;
}

.container {
    margin: 0 auto;
    max-width: 1100px;

    &.small {
        max-width: 800px;
    }
}

@media (max-width: 1100px) {
    .container {
        padding: 0 15px;

        &.small {
            padding: 0;
        }
    }
}

@media (max-width: 800px) {
    .container {
        &.small {
            padding: 0 15px;
        }
    }
}

.nudge-left {
    margin-right: 1rem !important;
}

.nudge-right {
    margin-left: 1rem !important;
}

.page-header {
    padding-bottom: 9px;
    margin: 40px 0 20px;
    border-bottom: common.$border;
}

.stretch-height {
    min-height: calc(100% - 56px);
}

.zEWidget-launcher {
    left: auto!important;
    right: 0!important;
}